import React from 'react';

import { Layout } from 'src/components/Layout';
import { ArrowButton } from 'src/components/ArrowButton';

import { BrainSVG } from 'src/components/SVGs/BrainSVG';
import { VisualSpacial } from 'src/components/SVGs/VisualSpacialSVG';
import { MusicalRythmic } from 'src/components/SVGs/MusicalRythmicSVG';
import { Interpersonal } from 'src/components/SVGs/InterpersonalSVG';
import { Naturalistic } from 'src/components/SVGs/NaturalisticSVG';
import { DiceGameSVG } from 'src/components/SVGs/DiceGameSVG';
import { DiscrepanciesSVG } from 'src/components/SVGs/DiscrepanciesSVG';
import { HiddenFiguresSVG } from 'src/components/SVGs/HiddenFiguresSVG';
import { PicturePuzzleSVG } from 'src/components/SVGs/WhereSVG';
import { Clock } from 'src/components/SVGs/ClockSVG';
import styles from './styles.module.css';
import { Puzzle } from 'src/model/PuzzleData';

interface Props {
	path: string;
	location: {
		href: string;
		origin: string;
		protocol: string;
		host: string;
		hostname: string;
		port: string;
		pathname: string;
		search: string;
		hash: string;
		state: {
			key: string;
		};
		key: string;
	};
	pageContext: {
		puzzleData: Puzzle;
	};
}

interface State {}

export default class PuzzleFrontPage extends React.Component<Props, State> {
	render() {
		const imageMap: { [key: string]: any } = {
			brain: <BrainSVG width={447} height={246} />,
			visualSpacial: <VisualSpacial width={447} height={246} />,
			musicalRythmic: <MusicalRythmic width={447} height={246} />,
			interpersonal: <Interpersonal width={447} height={246} />,
			naturalistic: <Naturalistic width={447} height={246} />,
			dice: <DiceGameSVG width={447} height={246} />,
			discrepancies: <DiscrepanciesSVG width={447} height={246} />,
			hiddenFigures: <HiddenFiguresSVG width={447} height={246} />,
			number: <BrainSVG width={447} height={246} />,
			where: <PicturePuzzleSVG width={447} height={246} />,
		};
		const puzzle = this.props.pageContext.puzzleData;

		const image = imageMap[puzzle.titleImage];
		const puzzleTestUrl = `puzzle-${puzzle.id}`;

		return (
			<Layout>
				<div className={styles.container}>
					<div className={styles.heading}>
						<div className={styles.backButton}>
							<ArrowButton to="/" caption="Back" isBackButton={true} />
						</div>
						<div className={styles.title}>{puzzle.title}</div>
						<div className={styles.emptySpaceholder} />
					</div>
					<div className={styles.subContainer}>
						<div>{image}</div>
						<div className={styles.text}>{puzzle.descriptionLine1}</div>
						{puzzle.descriptionLine2 && (
							<div className={styles.text}>{puzzle.descriptionLine2}</div>
						)}
						<div>
							<ArrowButton
								to={`/${puzzleTestUrl}`}
								gatsbyLinkState={{ puzzleId: puzzle.id }}
								caption="Start This Puzzle"
							/>
						</div>
						<div className={styles.duration}>
							<Clock />
							<p>
								Takes about {puzzle.expectedDurationMinutes}
								{puzzle.expectedDurationMinutes == 1 ? ' minute.' : ' minutes.'}
							</p>
						</div>
					</div>
				</div>
			</Layout>
		);
	}
}
